import theme from 'theme';
import { makeStyles } from '@material-ui/core/styles';

import { baseStyles } from 'suspended-page/styles';

export default makeStyles({
  ...baseStyles,

  headingMessageJustify: {
    marginTop: `${theme.spacing(2)}px !important`,
    marginBottom: `${theme.spacing(4)}px !important`,
    width: '90% !important',
    marginLeft: 'auto !important',
    marginRight: 'auto !important',
    [theme.breakpoints.up('sm')]: {
      width: '80% !important',
    },
    [theme.breakpoints.up('md')]: {
      width: '80% !important',
    },
    [theme.breakpoints.up('lg')]: {
      width: '45% !important',
    },
    [theme.breakpoints.up('xl')]: {
      width: '35% !important',
    },
  },
});
