import React from 'react';
import { array } from 'prop-types';
import loadable from '@loadable/component';

import { Skeleton } from '@material-ui/lab';

import useStyles from './styles';

const Typography = loadable(() => import('@material-ui/core/Typography'));
const Page = loadable(() => import('components/page'));
const LazyValuesBanner = loadable(() => import('components/banners/values'));

const LazyValuesBriefSection = loadable(() => import('views/our-values/breif-section'));
const LazyOverrideFooter = loadable(() => import('components/footer/override-footer'));

const SupendedValuesPage = ({ navItems = [] }) => {
  const styles = useStyles();
  const title = 'Our Beliefs and Values';
  const message = 'Why we build software, what\'s important to us';
  return (
    <Page
      title={title}
      description={message}
      noAuth>
          <LazyValuesBanner falback={<Skeleton
            animation="wave"
            component="div"
            height={300}
            className={styles.landingSkeletonBanner}
            variant="rect"
            />}/>

            <Typography variant="h2" component="h1" className={styles.heading} align="center">
              {title}
            </Typography>

            <Typography variant="h4" component="p" className={styles.headingMessage} align="center">
             {message}
            </Typography>

            <LazyValuesBriefSection/>

          <section>
            <Typography variant="h3" component="h2" className={styles.fourTopBotMargin} align="center">
             Community
            </Typography>
          </section>

          <section>
            <Typography variant="h3" component="h2" className={styles.fourTopBotMargin} align="center">
              Equality
            </Typography>
          </section>

          <section>
            <Typography variant="h3" component="h2" className={styles.fourTopBotMargin} align="center">
              Security
            </Typography>
          </section>

          <LazyOverrideFooter
              navItems={navItems}
              currentItem="Values"
              fallback={
                <Skeleton
                animation="wave"
                component="div"
                height={100}
                style={{
                  marginTop: -5,
                  marginRight: 10,
                  marginLeft: 10,
                }} />
              }
              />
        </Page>
  );
};

SupendedValuesPage.propTypes = { navItems: array };

export default SupendedValuesPage;
